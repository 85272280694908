<template>
  <CRow style="width:100%">
    <CImg
      v-show="false"
      src="https://s3.ap-northeast-1.amazonaws.com/src.img/NvDUdxLS/1/0/G1HG2aatuh.jpg"
    />
    <CCol>
      <!-- 現在の画像を表示するエレメント -->
      <CImg
        v-show="false"
        :src="this._get_avatar(this.$store.state.user_id)"
        shape="rounded-circle"
        :width="old_img_size"
      />
      <!-- アップロードする画像をループして表示するエレメント -->
      <CRow v-if="images.length > 0" style="margin-bottom: 20px">
        <CCol :col="new_img_size" v-for="(image, index) in images" style="margin-bottom: 10px" @click="setImage(index)">
          <CImg
            width="100%"
            height="auto"
            shape="rounded"
            :src="image.base64"
          />
          <CRow>
            <CCol class="col-12 text-center" style="padding:6px 15px 6px 15px">
              <CIcon v-if="chkFileSize(image.size)" size="lg" name="cil-check-circle" style="color:darkgreen;"></CIcon>
              <CIcon v-if="!chkFileSize(image.size)" size="lg" name="cil-x" style="color:red;"></CIcon>
              サイズ: {{ fileSize(image.size) }}
            </CCol>
          </CRow>
        </CCol>
      </CRow>
      <!-- Croppie -->
      <!-- ファイルを選択するボタン -->
      <CRow>
        <CCol v-if="images.length === 0" lg="6" col="10" class="offset-1 offset-lg-3">
          <label class="my-label">アップロードする写真を選択する
            <input
              type="file"
              multiple
              accept="image/*"
              style="display: none"
              v-on:change="loadFiles"
            />
          </label>
        </CCol>
        <!-- Croppie エレメント -->
        <CCol lg="12">
          <!-- Note that 'ref' is important here (value can be anything). read the description about `ref` below. -->
          <vue-croppie
            ref="croppieRef"
            :enableOrientation="true"
            :boundary="{ width: 250, height: 250}"
            :viewport="{ width: 200, height: 200, type: 'circle' }"
            :enableResize="false"
            :enableExif="true"
            @result="result"
            @update="update"
          />
        </CCol>
        <!-- ローテート＆保存ボタン -->
        <CCol v-show="this.images.length > 0" col="12">
          <CRow>
            <CCol lg="2" col="4" class="offset-3">
              <CButton
                v-show="this.images.length > 0"
                style="width:100%" color="secondary" @click="reSelect">再選択
              </CButton>
            </CCol>
            <CCol lg="2" col="4">
              <CButton
                style="width:100%" color="secondary" @click="cropViaEvent">保存する
              </CButton>
            </CCol>
            <CCol lg="2" col="4">
              <CButton
                style="width:100%; padding:6px 2px 6px 2px"
                variant="ghost"
                color="secondary"
                @click="rotate(90)">
                <CIcon name="cil-loop-circular"/>
                回転
              </CButton>
            </CCol>
          </CRow>
        </CCol>
      </CRow>
    </CCol>
  </CRow>
</template>

<script>
import Comm from '@/views/v1/lib/Comm.js'
import VueCroppie from 'vue-croppie';


export default {
  name: "EditLogo",
  mixins: [Comm],/* ここでミックスインを追加 */
  comments: {
    "vue-croppie": VueCroppie
  },
  props: {
    isShow: false
  },
  watch: {
    isShow: function(value){
      if(value === false){
        this.images = []
        this.index = 0
      }
    }
  },
  data() {
    return {
      showFileChoose: true,
      names: '',
      size: '',
      type: '',
      b64: '',
      images: [],
      index: 0,
      message: '',
      cropped: null,
      reader: '',
      files: []
    }
  },
  mounted() {
    const url = this.$store.state.image_path + 'no_image.jpg'
    this.$refs.croppieRef.bind({
      url: url,
    }).then(function (result) {
      console.log(result)
    }).catch(function (error) {
      console.log(error)
    })

  },
  computed: {
    new_img_size: function () {
      return '3'
    },
    old_img_size: function () {
      if (this.images.length === 0) {
        return '50%'
      } else {
        return '15%'
      }
    },
    chkFileSize: function () {
      return function (value) {
        if (value > 3 * 1024 * 1024) {
          return false
        }
        return true
      }
    },
    fileSize: function () {
      return function (value) {
        if (value === 0 || value === '') {
          return "0バイト"
        }
        if (value < 1024) {
          return String(value) + 'Byte'
        }
        if (value < 1024 * 1024) {
          return String((Number(value) / 1024).toFixed(2)) + 'KB'
        }
        return String((Number(value) / 1024 / 1024).toFixed(2)) + 'MB'
      }
    },
  },
  methods: {
    reSelect(){
      this.images = []
      this.index = 0

    },
    async upload(value) {
      const image = {
        name: this.images[this.index].name,
        type: this.images[this.index].type,
        size: this.images[this.index].size,
        base64: value
      }
      const item = {
        family_id: this.$store.state.family_id,
        user_id: this.$store.state.user_id,
        job_id: 0,//job_id=0は自分のアイコン画像
        images: [image]
      }
      // console.log(item)
      const response = await this._imageUpload(item)
      console.log(response)
    },
    setImage(value) {
      this.reader.readAsDataURL(this.files[value])
      this.index = value
    },
    loadFiles(e) {
      this.files = e.target.files
      const event = e
      this.reader = new FileReader();
      this.reader.onload = e => {
        this.$refs.croppieRef.bind({
          url: e.target.result
        });
      };
      this.reader.readAsDataURL(this.files[0]);
      this.show(this.files) //画像イメージを上に表示するための処理
    },
    show(e) {
      Object.keys(e).map(async key => {
        if (e[key].type === 'image/jpeg' || e[key].type === 'image/png') {
          const base64_img = await this.getBase64(e[key])
            .then(image => {
              return image
            })
          //https://chusotsu-program.com/js-base64-encode/
          this.images.push({
              name: e[key].name,
              size: e[key].size,
              type: e[key].type,
              base64: base64_img,
            })
        }
      }, this)
    },
    async getBase64(file) {
      /* Promiseにアロー関数を渡して非同期処理を書く。*/
      return new Promise((resolve, reject) => {

        /* 組み込みオブジェクトを使い、Base64エンコードされた画像データを取得  */
        const reader = new FileReader()
        reader.readAsDataURL(file)

        /* 成功時と失敗時の処理。resolveの引数に結果を渡して、のちの.then(result => ...)で受け取る  */
        reader.onload = () => resolve(reader.result)
      })
    },
    // CALLBACK USAGE
    crop() {
      // Here we are getting the result via callback function
      // and set the result to this.cropped which is being
      // used to display the result above.
      let options = {
        format: 'jpeg',
        circle: true
      }
      this.$refs.croppieRef.result(options, (output) => {
        this.cropped = output;
      });
    },
    // EVENT USAGE
    async cropViaEvent() {
      const options = {
        type: 'base64',
        size: {width: 400, height: 400},
        format: 'jpeg',
        circle: false
      }
      const b64img = await this.$refs.croppieRef.result(options)
      await this.upload(b64img)
    },
    result(output) {
      this.cropped = output;
    },
    update(val) {
      // console.log(val);
    },
    rotate(rotationAngle) {
      // Rotates the image
      this.$refs.croppieRef.rotate(rotationAngle);
    }
  }
}
</script>

<style scoped>
label.my-label {
  display: block;
  /*width: 160px;*/
  padding: 0.8em;
  text-align: center;
  text-decoration: none;
  color: #4c4f54;
  border: 2px solid #4c4f54;
  border-radius: 3px;
  transition: .4s;
}

label.my-label:hover {
  background: #4c4f54;
  color: #fff;
}
</style>
