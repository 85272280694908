<template>
  <CForm class="row">
    <CButton v-if="false" color="success" @click="showProps">CreateJobコンポーネントのボタン</CButton>
    <CCol col="6">
      <CInput
        label="ユーザ名"
        placeholder="xxxxxx"
        horizontal
        :disabled="true"
        description="ご家族の中で唯一の名前としてください。"
        v-bind:value="username"
        v-on:change="$emit('update:username', $event)"
      />
    </CCol>
    <CCol col="6">
      <CInput
        :type="showPassword"
        label="パスワード"
        horizontal
        :disabled="is_disabled"
        v-bind:value="password"
        v-on:change="$emit('update:password', $event)"
      >
        <template #append>
          <CButton
            @click="show_password = !show_password"
            color="secondary"><CIcon name="cil-check-circle"/>
          </CButton>
        </template>
      </CInput>
    </CCol>
    <CCol col="12" style="padding:0">
      <CCol col="12" lg="6">
        <CInput
          label="メール"
          horizontal
          placeholder="yamada@taro.com"
          :disabled="is_disabled"
          v-bind:value="mail"
          v-on:change="$emit('update:mail', $event)"
          description="メールがある人は入力してください。"
        />
      </CCol>
    </CCol>
    <CCol col="6">
      <CInput
        label="表示名"
        horizontal
        placeholder="ぱぱさん"
        :disabled="is_disabled"
        v-bind:value="_nickname"
        v-on:change="$emit('update:_nickname', $event)"
        description="表示される名前です。"
      />
    </CCol>
    <CCol col="6">
      <CSelect
        label="利用者種別"
        horizontal
        disabled="true"
        :options="isAdminOptions"
        v-bind:value="is_admin"
        v-on:change="$emit('update:is_admin', $event)"
      />
    </CCol>
    <CCol col="6">
      <CInput
        label="名字"
        horizontal
        placeholder="山田"
        :disabled="is_disabled"
        v-bind:value="first_name"
        v-on:change="$emit('update:first_name', $event)"
      />
    </CCol>
    <CCol col="6">
      <CInput
        label="名前"
        horizontal
        placeholder="太郎"
        :disabled="is_disabled"
        v-bind:value="last_name"
        v-on:change="$emit('update:last_name', $event)"
      />
    </CCol>
  </CForm>
</template>

<script>
import Comm from '@/views/v1/lib/Comm.js'

export default {
  name: "CreateUser",
  mixins: [Comm],/* ここでミックスインを追加 */
  components: {},
  props: {
    username: {
      type: String,
      default: ''
    },
    mail: {
      type: String,
      default: ''
    },
    _nickname: {
      type: String,
      default: ''
    },
    last_name: {
      type: String,
      default: ''
    },
    first_name: {
      type: String,
      default: ''
    },
    is_admin: {
      type: Boolean,
      default: false
    },
    is_disabled: {
      type: Boolean,
      default: false
    }
  },
  setup() {
  },
  mounted() {
  },
  data() {
    return {
      show_password: false,
      password: '',
      isAdminOptions: [
        {value: false, label: "利用者"},
        {value: true, label: "管理者"},

      ],
    }
  },
  computed: {
    showPassword() {
      if (this.show_password) {
        return "text"
      } else {
        return "password"
      }
    },
  },
  methods: {
    showProps() {
      console.log(this.$props)
    }
  }
}
</script>

<style scoped>

</style>
