<template>
  <div>
    <CRow>
      <CCol lg="12" col="5" style="padding:15px 15px 15px 15px">
        <!-- メインロゴ画像 -->
        <div style="position: relative">
          <div v-if="is_edit" style="position: absolute; bottom: 0px; right:0px">
            <CButton
              @click="emitEditButton"
              variant="outline"
              color="secondary"
              :disabled="false"
              size="lg"
              shape="pill"
            >
              <CIcon name="cil-camera"/>
            </CButton>
          </div>
          <div>
            <CImg
              :src="_get_avatar(this.$store.state.user_id)"
              shape="rounded-circle"
              width="100%"
            />
          </div>
        </div>
      </CCol>

      <CCol lg="12" col="7" style="margin: 2px 0 0 0;">
        <h5>{{ nickname }}</h5>
      </CCol>
      <CCol lg="6" col="6" style="margin: 0 0 20px 0;padding-right:5px">
        <CButton
          v-if="!is_edit"
          variant=""
          shape=""
          color="primary"
          class="edit-button"
          v-on:click="showCreateJobByStep"
        >お仕事の追加
        </CButton>
      </CCol>
      <CCol lg="6" col="6" style="margin: 0 0 20px 0;padding-left:5px">
        <CButton
          v-if="!is_edit"
          variant="outline"
          color="secondary"
          class="edit-button"
          v-on:click="show"
        >プロフィール編集
        </CButton>
      </CCol>
      <CCol v-show="!this.$store.state.showMemberList" lg="12">
        <CListGroup style="width:100%">
          <CListGroupItem
            @click="_gets_family"
            style="padding:3px 20px 3px 15px;cursor:pointer"
          >
            メンバ一覧
            <div style="width:100%;margin-top:-1.4rem" class="text-right">
              <CIcon
                name="cil-reload" size="sm"
              />
            </div>
          </CListGroupItem>
          <CListGroupItem
            v-for="member in this.$store.state.your_family" :key="member['id']"
            class="d-flex justify-content-between align-items-center"
          >
            <CRow>
              <CCol col="3">
                <div
                  @mouseenter="member['show_picture_sw'] = true"
                  @mouseleave="member['show_picture_sw'] = false"
                >
                  <CImg
                    style="width:100%;height:auto"
                    shape="rounded-circle"
                    :src="_get_avatar(member['id'])"
                  />
                </div>
                <div v-show="member['show_picture_sw']"
                     class="dropdown-menu show"
                     style="top:-440px; left:-40px"
                >
                  <CImg
                    style="width:400px;height:auto;margin:10px 10px 10px 10px;"
                    shape="rounded-circle"
                    :src="_get_avatar(member['id'])"
                  />
                </div>
              </CCol>
              <CCol col="9">
                <div>{{ member['nickname'] }}</div>
                <div>{{ member['mail'] }}</div>
              </CCol>
            </CRow>
          </CListGroupItem>
        </CListGroup>
      </CCol>
    </CRow>
  </div>
</template>

<script>
import Comm from '@/views/v1/lib/Comm.js'

export default {
  name: "MyLogo",
  mixins: [Comm],/* ここでミックスインを追加 */
  props: {
    is_edit: false,
  },
  components: {},
  data() {
    return {
      showModalEditLogo: true
    }
  },
  computed: {
    myImagePath: function () {
      return function (value) {
        const member_info = this.$store.state.your_family.filter(member => member.id === value)
        return `${this.$store.state.image_path}NvDUdxLS/${this.$store.state.user_id}/0/${member_info[0]["avatar"]}`
        // return `${this.$store.state.image_path}${value}.jpg`
      }
    },
    imagePath: function () {
      return function (value) {
        const member_info = this.$store.state.your_family.filter(member => member.id === value)
        return `${this.$store.state.image_path}NvDUdxLS/${value}/0/${member_info[0]["avatar"]}`
        // return `${this.$store.state.image_path}${value}.jpg`
      }
    },
  },
  methods: {
    emitEditButton() {
      this.$emit('push:edit_picture', true)
    },
    show() {
      const item = {
        username: this.$store.state.username,
        mail: this.$store.state.mail,
        _nickname: this.$store.state.nickname,
        last_name: this.$store.state.last_name,
        first_name: this.$store.state.first_name,
        is_admin: this.$store.state.is_admin,
        is_disabled: false
      }
      this.$router.push({
        name: 'EditUser',
        params: {"_item": item}
      })
    },
    showCreateJobByStep() {
      this.$emit('click-create-job', true)
    }
  }
}
</script>

<style scoped>
.edit-button {
  margin-top: 5px;
  width: 100%;
}
</style>
