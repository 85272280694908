<template>
  <div>
    <CCard>
      <CCardHeader>
        <div class="card-header-actions">
          <div style="cursor:pointer" @click="$store.state.showMemberList = !$store.state.showMemberList">
            <CIcon :name="`cil-chevron-${$store.state.showMemberList ? 'bottom' : 'top'}`"/>
          </div>
        </div>
      </CCardHeader>
      <CCardBody>
        <CRow>
          <CCol lg="3">
            <my-logo
              :is_edit="true"
              v-on:push:edit_picture="showModalEditLogo = $event"
            />
          </CCol>
          <CCol lg="9">
            <create-user
              v-bind:username.sync="username"
              v-bind:mail.sync="mail"
              v-bind:_nickname.sync="_nickname"
              v-bind:last_name.sync="last_name"
              v-bind:first_name.sync="first_name"
              v-bind:is_admin="is_admin"
              v-on:update:is_admin="is_admin = $event.target.value"
              v-on:update:password="item.password = $event"
              v-bind:is_disabled="false"
            />
          </CCol>
        </CRow>
      </CCardBody>
      <CCardFooter>
        <CRow>
          <CCol class="col-lg-2">
            <CButton
              style="width:100%"
              variant="outline"
              @click="moveToListJobs"
              size="sm"
              color="warning"
            >キャンセル
            </CButton>
          </CCol>
          <CCol class="col-lg-2 offset-lg-8">
            <CButton
              style="width:100%"
              variant="outline"
              @click="update"
              size="sm"
              color="primary"
            >送信
            </CButton>
          </CCol>
        </CRow>
      </CCardFooter>
    </CCard>
    <CModal
      title="ロゴ画像を更新する"
      size="lg"
      :show.sync="showModalEditLogo"
    >
      <edit-logo
        :is-show="showModalEditLogo"
      />
      <template v-slot:footer-wrapper>
        <div></div>
      </template>
    </CModal>
  </div>
</template>

<script>
import Comm from '@/views/v1/lib/Comm.js'
import CreateUser from "@/views/v1/components/CreateUser";
import MyLogo from "@/views/v1/components/MyLogo"
import EditLogo from "@/views/v1/components/EditLogo";

export default {
  name: "EditUser",
  mixins: [Comm],/* ここでミックスインを追加 */
  props: {
    _item: {
      username: [String, Number],
      mail: [String, Number],
      _nickname: [String, Number],
      last_name: String,
      first_name: String,
      is_admin: Boolean,
      is_disabled: Boolean
    },
  },
  mounted() {
  },
  components: {
    "create-user": CreateUser,
    "my-logo": MyLogo,
    "edit-logo": EditLogo
  },
  computed: {
    username: {
      set: function (value) {
        this.item.username = value
      },
      get() {
        if (this.item.username === undefined) {
          return this._item.username
        } else {
          return this.item.username
        }
      },
    },
    mail: {
      set: function (value) {
        this.item.mail = value
      },
      get() {
        if (this.item.mail === undefined) {
          return this._item.mail
        } else {
          return this.item.mail
        }
      },
    },
    _nickname: {
      set: function (value) {
        this.item._nickname = value
      },
      get() {
        if (this.item._nickname === undefined) {
          return this._item._nickname
        } else {
          return this.item._nickname
        }
      },
    },
    last_name: {
      set: function (value) {
        this.item.last_name = value
      },
      get() {
        if (this.item.last_name === undefined) {
          return this._item.last_name
        } else {
          return this.item.last_name
        }
      },
    },
    first_name: {
      set: function (value) {
        this.item.first_name = value
      },
      get() {
        if (this.item.first_name === undefined) {
          return this._item.first_name
        } else {
          return this.item.first_name
        }
      },
    },
    is_admin: {
      set: function (value) {
        this.item.is_admin = value
      },
      get() {
        if (this.item.is_admin === undefined) {
          return this._item.is_admin
        } else {
          return this.item.is_admin
        }
      },
    },
  },
  data() {
    return {
      showModalEditLogo: false,
      item: {
        username: undefined,
        password: undefined,
        mail: undefined,
        _nickname: undefined,
        last_name: undefined,
        first_name: undefined,
        is_admin: undefined,
        is_disabled: undefined,
      }
    }
  },
  methods: {
    closeModal(e){
      console.log(e)
    },
    show() {
      console.log("props ", this.$props)
      console.log("data ", this.item)
    },
    showEvent(e){
      console.log(e)
    },
    moveToListJobs() {
      this.$router.push({name: 'ListJobs'})
    },
    async update() {
      const item = {
        id: this.$store.state.user_id,
        family_id: this.$store.state.family_id,
        username: this.username,
        mail: this.mail,
        nickname: this._nickname,
        last_name: this.last_name,
        first_name: this.first_name,
        is_admin: this.is_admin
      }
      if(this.item.password !== undefined){
        item["password"] = this.item.password
      }
      console.log(item)
      const response = await this._updateUser(item)
      // console.log(response)
      await this.$router.push({path: '/'})
    }
  }
}
</script>

<style scoped>
</style>
